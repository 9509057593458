.card {
    background: var(--card-background);
    box-shadow: 0 6px 10px rgba(0, 0, 0, .02), 0 0 6px rgba(0, 0, 0, .03);
    transition: .3s transform cubic-bezier(.155, 1.105, .295, 1.12), .3s box-shadow, .3s -webkit-transform cubic-bezier(.155, 1.105, .295, 1.12);
    /* padding: 2em 2em; */
    border-radius: 10px;
    text-align: center;
    overflow: hidden;
    cursor: pointer;
    border: 1px solid var(--card-border);


}

.card:hover {
    transform: scale(1.05);
    box-shadow: 0 10px 20px rgba(0, 0, 0, .12), 0 4px 8px rgba(0, 0, 0, .06);
}

.card-img {
    object-fit: cover;
    width: 100%;

}

.card-content {
    padding: 1em 2em;
    text-align: left;
}

.card-title {
    color: var(--card-title);
    font-size: 1.125rem;
    font-weight: 600;
    margin: 0;
}

.card-subtitle {
    color: var(--card-subtitle);
    font-weight: 400;
    margin: 0;
    margin-top: 0.4em;
    font-size: 0.75rem;
}

.card-desc {
    color: var(--card-desc);
    margin: 1em 0;
    font-size: 0.85rem;
    font-weight: 300;
}