.cans {
    background: var(--skills-background);
    padding: 6em 0;

}

.can-title {
    font-size: 1.125rem;
    margin: 0;
    font-weight: 600;
    margin-top: .5em;
    color: var(--project-details-subtitle);
}



.can-desc {
    color: var(--skills-desc);
    font-weight: 300;
    margin-top: 0.8em;
    font-size: 0.8rem;
    line-height: 1.6182;

}

.cans-content {
    margin-top: 1em;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    gap: 1.5em;

}

/* 
.cans-content .can-content:nth-child(2){
    background-color: #371BB1;
    color: white;
} */


.can-content {
    background: var(--card-background);
    /* border: 1px solid var(--card-border); */
    border-radius: 6px;
    padding: 2em 3em;
    cursor: pointer;
    box-shadow: rgba(50, 50, 93, 0.01) 0px 50px 100px -20px, rgba(0, 0, 0, 0.1) 0px 30px 60px -30px;
    transition: .3s ease;
}




.can-content:hover {

    box-shadow: rgba(50, 50, 93, 0.15) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}