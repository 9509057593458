.featured-projects-wrapper{
    margin-top: 0em;
    background-color: #faf9f9;
    padding: 4em 0em;
}

.featured-projects {
    display: grid;
    gap: 1em;
}

.featured-projects-title {
    color: var(--featured-projects-title);
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 1.7px;
    margin-bottom: 1.5em;
}

.loader {
    text-align: center;

}

