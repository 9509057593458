.paintings-wrapper {
    /* padding: 2em 0; */
    height: 100vh;
    background: var(--theme-background);


}

.paintings-container {
    display: flex;
    width: 100%;
    padding: 2em 1em;
    box-sizing: border-box;
    height: 100vh;
    background: var(--theme-background);
}

.box {
    flex: 1;
    overflow: hidden;
    transition: .5s;
    margin: 0 1em;
    box-shadow: rgba(50, 50, 93, 0.01) 0px 50px 100px -20px, rgba(0, 0, 0, 0.1) 0px 30px 60px -30px;
    line-height: 0;
    border-radius: 6px;
    background: var(--painting-background);
    cursor: pointer;
}

.box>img {
    /* width: 200%; */
    height: calc(100% - 10vh);
    object-fit: cover;
    transition: .5s;
}

.box>span {
    font-size: 2.6vh;
    display: block;
    text-align: center;
    height: 10vh;
    line-height: 2.6;
    color: var(--painting-text);
}


.box:hover {
    flex: 1 1 50%;
    box-shadow: rgba(50, 50, 93, 0.15) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;

}

.box:hover>img {
    width: 100%;
    height: 100%;
}

@media(max-width:768px) {
    .paintings-container {
        display: flex;
        padding: 1em 1em;

        flex-direction: column;
    }

    .box {

        margin: 0.5em 1em;

    }
}