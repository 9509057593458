@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap);
/* @import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap'); */

*,
*::after,
*::before {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.3;

}

body ::selection {
  color: #12151F;
  background: #05F4B7;

}

/*layout*/
.container {
  width: 90%;
  max-width: 1040px;
  margin: 0 auto;
}

/* ::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background-color: #ffffff;
  border-radius: 100px;

}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #371BB1;
  border-radius: 100px;

} */


.light {

  --toggle-button: #12151F;

  --theme-background: #ffffff;

  --header-links: #828282;
  --header-links-hover: #371BB1;

  --header-logo: #12151F;


  --hero-background: #ffffff;
  --hero-title: #12151F;
  --hero-subtitle: #371BB1;
  --hero-desc: #828282;

  --social-links: #828282;
  --social-links-hover: #371BB1;

  --loader-color: #371BB1;


  --skills-title: #12151F;
  --skills-subtitle: #fff;
  --skills-desc: #828282;
  --skills-background: #ffffff;
  --skills-subtitle-background: #371BB1;




  --footer-background: #ffffff;
  --footer-title: #12151F;
  --footer-subtitle: #828282;
  --footer-copyrights: #828282;


  --projects-background: #fff;
  --card-background: #fff;
  --card-border: #ececec;

  --card-title: #12151F;
  --card-subtitle: #828282;
  --card-desc: #828282;

  --featured-card-title: #12151F;
  --featured-card-hover-title: #ffff;
  --featured-card-subtitle: #828282;
  --featured-card-hover-subtitle: #fff;
  --featured-card-button: #371BB1;
  --featured-card-hover-background: #371BB1;
  --featured-card-background: #fff;
  --featured-projects-title: #828282;


  --project-details-heading: #371BB1;
  --project-details-title: #fff;
  --project-details-title-background: #371BB1;
  --project-details-subtitle: #12151F;
  --project-details-desc: #4b4b4b;
  --project-details-btn-background: #12151F;
  --project-details-btn-text: #fff;
  --project-details-btn-hover: #371BB1;


  --about-me-title: #12151F;
  --about-me-sbutitle: #371BB1;
  --about-me-desc: #4b4b4b;
  --more-info-title: #12151F;
  --list-color: #4b4b4b;

  --painting-background: #fff;
  --painting-text: #12151F;

}


.dark {

  --toggle-button: #05F4B7;

  --theme-background: #12151F;

  --header-links: #BDBDBD;
  --header-links-hover: #05F4B7;
  --header-logo: #fff;


  --hero-background: #12151F;
  --hero-title: #fff;
  --hero-subtitle: #05F4B7;
  --hero-desc: #BDBDBD;


  --social-links: #BDBDBD;
  --social-links-hover: #05F4B7;

  --loader-color: #05F4B7;



  --skills-title: #ffffff;
  --skills-subtitle: #12151F;
  --skills-desc: #BDBDBD;
  --skills-background: #12151F;
  --skills-subtitle-background: #05F4B7;




  --footer-background: #12151F;
  --footer-title: #ffff;
  --footer-subtitle: #BDBDBD;
  --footer-copyrights: #BDBDBD;


  --projects-background: #12151F;
  --card-background: #1b1f2b;
  --card-border: #1b1f2b;

  --card-title: #ffff;
  --card-subtitle: #BDBDBD;
  --card-desc: #BDBDBD;


  --featured-card-title: #ffff;
  --featured-card-hover-title: #12151F;
  --featured-card-background: #1b1f2b;
  --featured-card-subtitle: #BDBDBD;
  --featured-card-hover-subtitle: #12151F;
  --featured-card-button: #05F4B7;
  --featured-card-hover-background: #05F4B7;
  --featured-projects-title: #BDBDBD;


  --project-details-heading: #05F4B7;
  --project-details-title: #12151F;
  --project-details-title-background: #05F4B7;
  --project-details-subtitle: #fff;
  --project-details-desc: #BDBDBD;

  --project-details-btn-background: #fff;
  --project-details-btn-text: #12151F;
  --project-details-btn-hover: #05F4B7;

  --about-me-title: #ffff;
  --about-me-sbutitle: #05F4B7;
  --about-me-desc: #BDBDBD;
  --more-info-title: #ffff;

  --list-color: #BDBDBD;


  --painting-background: #1b1f2b;
  --painting-text: #05F4B7;
}




.app {

  background: var(--hero-background);

}





h1,
h2,
h3 {
  margin-top: 0;
  margin-bottom: 0;
}



img {
  width: 100%;
  object-fit: cover;
}






.card {
    background: var(--card-background);
    box-shadow: 0 6px 10px rgba(0, 0, 0, .02), 0 0 6px rgba(0, 0, 0, .03);
    transition: .3s transform cubic-bezier(.155, 1.105, .295, 1.12), .3s box-shadow, .3s -webkit-transform cubic-bezier(.155, 1.105, .295, 1.12);
    /* padding: 2em 2em; */
    border-radius: 10px;
    text-align: center;
    overflow: hidden;
    cursor: pointer;
    border: 1px solid var(--card-border);


}

.card:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    box-shadow: 0 10px 20px rgba(0, 0, 0, .12), 0 4px 8px rgba(0, 0, 0, .06);
}

.card-img {
    object-fit: cover;
    width: 100%;

}

.card-content {
    padding: 1em 2em;
    text-align: left;
}

.card-title {
    color: var(--card-title);
    font-size: 1.125rem;
    font-weight: 600;
    margin: 0;
}

.card-subtitle {
    color: var(--card-subtitle);
    font-weight: 400;
    margin: 0;
    margin-top: 0.4em;
    font-size: 0.75rem;
}

.card-desc {
    color: var(--card-desc);
    margin: 1em 0;
    font-size: 0.85rem;
    font-weight: 300;
}
.projects {
    background: var(--projects-background);

    padding: 8em 0;
}

.projects-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    grid-gap: 1.5em;
    gap: 1.5em;

}
.skills {
    background: var(--skills-background);
    padding: 3em 0;
}

.skills-title {
    color: var(--skills-title);
    font-weight: 600;
    margin: 0;
}

.skills-subtitle {
    color: var(--skills-subtitle);
    font-weight: 400;
    margin-bottom: 00.5em;
    background: var(--skills-subtitle-background);
    padding: 0em 0.2em;
    border-radius: 3px;
}

.skills-desc {
    color: var(--skills-desc);
    font-weight: 300;
    margin: 0;
    margin-bottom: 0.2em;

}

.skills-content {
    margin-top: 1em;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    grid-gap: 1.5em;
    gap: 1.5em;

}

.skill-content {
    margin-top: 1.5em;
    /* border: 1px solid red; */
}
.cans {
    background: var(--skills-background);
    padding: 6em 0;

}

.can-title {
    font-size: 1.125rem;
    margin: 0;
    font-weight: 600;
    margin-top: .5em;
    color: var(--project-details-subtitle);
}



.can-desc {
    color: var(--skills-desc);
    font-weight: 300;
    margin-top: 0.8em;
    font-size: 0.8rem;
    line-height: 1.6182;

}

.cans-content {
    margin-top: 1em;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    grid-gap: 1.5em;
    gap: 1.5em;

}

/* 
.cans-content .can-content:nth-child(2){
    background-color: #371BB1;
    color: white;
} */


.can-content {
    background: var(--card-background);
    /* border: 1px solid var(--card-border); */
    border-radius: 6px;
    padding: 2em 3em;
    cursor: pointer;
    box-shadow: rgba(50, 50, 93, 0.01) 0px 50px 100px -20px, rgba(0, 0, 0, 0.1) 0px 30px 60px -30px;
    transition: .3s ease;
}




.can-content:hover {

    box-shadow: rgba(50, 50, 93, 0.15) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}
footer {
    background: var(--footer-background);
    padding: 2.5em 0;
    padding: 5em 0;
}

.footer-container {
    display: flex;
    flex-direction: column;
}

.footer-title {
    color: var(--footer-title);
    margin: 0;
    font-size: 1.3rem;
    margin-top: 1em;
    font-weight: 600;
}

.footer-subtitle {
    color: var(--footer-subtitle);
    margin: 0;
    margin-top: 0.8em;
    font-weight: 400;
}

.footer-link{
    color: var(--footer-subtitle);
    margin: 0;
    margin-top: em;
    display: inline-block;
    margin-top: 0.5em;
    font-weight: 300;
}
.footer-copyrights {
    color: var(--footer-copyrights);
    font-size: 0.75rem;
    font-weight: 300;
    margin-top: 2em;
    opacity: 0.7;
    margin-bottom: 0;
    text-align: center;
}

@media(min-width:767px){
    .footer-container {
        flex-direction: row;
        justify-content: space-between;
     
    }
}

.featured-projects-wrapper{
    margin-top: 0em;
    background-color: #faf9f9;
    padding: 4em 0em;
}

.featured-projects {
    display: grid;
    grid-gap: 1em;
    gap: 1em;
}

.featured-projects-title {
    color: var(--featured-projects-title);
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 1.7px;
    margin-bottom: 1.5em;
}

.loader {
    text-align: center;

}


.paintings-wrapper {
    /* padding: 2em 0; */
    height: 100vh;
    background: var(--theme-background);


}

.paintings-container {
    display: flex;
    width: 100%;
    padding: 2em 1em;
    box-sizing: border-box;
    height: 100vh;
    background: var(--theme-background);
}

.box {
    flex: 1 1;
    overflow: hidden;
    transition: .5s;
    margin: 0 1em;
    box-shadow: rgba(50, 50, 93, 0.01) 0px 50px 100px -20px, rgba(0, 0, 0, 0.1) 0px 30px 60px -30px;
    line-height: 0;
    border-radius: 6px;
    background: var(--painting-background);
    cursor: pointer;
}

.box>img {
    /* width: 200%; */
    height: calc(100% - 10vh);
    object-fit: cover;
    transition: .5s;
}

.box>span {
    font-size: 2.6vh;
    display: block;
    text-align: center;
    height: 10vh;
    line-height: 2.6;
    color: var(--painting-text);
}


.box:hover {
    flex: 1 1 50%;
    box-shadow: rgba(50, 50, 93, 0.15) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;

}

.box:hover>img {
    width: 100%;
    height: 100%;
}

@media(max-width:768px) {
    .paintings-container {
        display: flex;
        padding: 1em 1em;

        flex-direction: column;
    }

    .box {

        margin: 0.5em 1em;

    }
}
.project-details-wrapper {}


.project-details-container {
    padding: 2.5em 0;
}

.project-details-container h1 {
    margin: 0;
    margin-bottom: 1em;
    color: var(--project-details-heading);
    font-size: 1.5rem;
    font-weight: 700;
    margin-top: 1.5em;

}

.project-details-container a {
    text-decoration: none;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    background-color: var(--project-details-btn-background);
    color: var(--project-details-btn-text);
    padding: 0.8em 1.9em;
    display: inline-block;
    border-radius: 6px;
    font-weight: 500;
}

.project-details-container a:hover {
    background-color: var(--project-details-btn-hover);
}

.project-details-container ul li::marker {
    color: var(--list-color);
    /* bullet color */
}

.project-details-container ol li::marker {
    color: var(--list-color);
    /* bullet color */
}

.project-details-container img {
    padding: 1em 0;
    display: block;
  


}



.project-details-container h4 {
    font-size: 1.125rem;
    margin: 0;
    font-weight: 600;
    margin-top: 1.5em;
    color: var(--project-details-subtitle);

}

.project-details-container h2 {
    margin: 0;
    margin-bottom: 1em;
    color: var(--project-details-title);
    font-size: 1.2rem;
    background-color: var(--project-details-title-background);
    font-weight: 500;
    margin-top: 1em;
    padding: 0em 0.2em;
    border-radius: 3px;
}

.project-details-container hr {
    border: 1px solid #eee;
}

.project-details-container p {
    font-weight: 300;
    line-height: 30px;
    margin: 0;
    margin-top: 0.5em;
    color: var(--project-details-desc);

}
