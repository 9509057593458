.project-details-wrapper {}


.project-details-container {
    padding: 2.5em 0;
}

.project-details-container h1 {
    margin: 0;
    margin-bottom: 1em;
    color: var(--project-details-heading);
    font-size: 1.5rem;
    font-weight: 700;
    margin-top: 1.5em;

}

.project-details-container a {
    text-decoration: none;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    background-color: var(--project-details-btn-background);
    color: var(--project-details-btn-text);
    padding: 0.8em 1.9em;
    display: inline-block;
    border-radius: 6px;
    font-weight: 500;
}

.project-details-container a:hover {
    background-color: var(--project-details-btn-hover);
}

.project-details-container ul li::marker {
    color: var(--list-color);
    /* bullet color */
}

.project-details-container ol li::marker {
    color: var(--list-color);
    /* bullet color */
}

.project-details-container img {
    padding: 1em 0;
    display: block;
  


}



.project-details-container h4 {
    font-size: 1.125rem;
    margin: 0;
    font-weight: 600;
    margin-top: 1.5em;
    color: var(--project-details-subtitle);

}

.project-details-container h2 {
    margin: 0;
    margin-bottom: 1em;
    color: var(--project-details-title);
    font-size: 1.2rem;
    background-color: var(--project-details-title-background);
    font-weight: 500;
    margin-top: 1em;
    padding: 0em 0.2em;
    border-radius: 3px;
}

.project-details-container hr {
    border: 1px solid #eee;
}

.project-details-container p {
    font-weight: 300;
    line-height: 30px;
    margin: 0;
    margin-top: 0.5em;
    color: var(--project-details-desc);

}