@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap'); */

*,
*::after,
*::before {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.3;

}

body ::selection {
  color: #12151F;
  background: #05F4B7;

}

/*layout*/
.container {
  width: 90%;
  max-width: 1040px;
  margin: 0 auto;
}

/* ::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background-color: #ffffff;
  border-radius: 100px;

}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #371BB1;
  border-radius: 100px;

} */


.light {

  --toggle-button: #12151F;

  --theme-background: #ffffff;

  --header-links: #828282;
  --header-links-hover: #371BB1;

  --header-logo: #12151F;


  --hero-background: #ffffff;
  --hero-title: #12151F;
  --hero-subtitle: #371BB1;
  --hero-desc: #828282;

  --social-links: #828282;
  --social-links-hover: #371BB1;

  --loader-color: #371BB1;


  --skills-title: #12151F;
  --skills-subtitle: #fff;
  --skills-desc: #828282;
  --skills-background: #ffffff;
  --skills-subtitle-background: #371BB1;




  --footer-background: #ffffff;
  --footer-title: #12151F;
  --footer-subtitle: #828282;
  --footer-copyrights: #828282;


  --projects-background: #fff;
  --card-background: #fff;
  --card-border: #ececec;

  --card-title: #12151F;
  --card-subtitle: #828282;
  --card-desc: #828282;

  --featured-card-title: #12151F;
  --featured-card-hover-title: #ffff;
  --featured-card-subtitle: #828282;
  --featured-card-hover-subtitle: #fff;
  --featured-card-button: #371BB1;
  --featured-card-hover-background: #371BB1;
  --featured-card-background: #fff;
  --featured-projects-title: #828282;


  --project-details-heading: #371BB1;
  --project-details-title: #fff;
  --project-details-title-background: #371BB1;
  --project-details-subtitle: #12151F;
  --project-details-desc: #4b4b4b;
  --project-details-btn-background: #12151F;
  --project-details-btn-text: #fff;
  --project-details-btn-hover: #371BB1;


  --about-me-title: #12151F;
  --about-me-sbutitle: #371BB1;
  --about-me-desc: #4b4b4b;
  --more-info-title: #12151F;
  --list-color: #4b4b4b;

  --painting-background: #fff;
  --painting-text: #12151F;

}


.dark {

  --toggle-button: #05F4B7;

  --theme-background: #12151F;

  --header-links: #BDBDBD;
  --header-links-hover: #05F4B7;
  --header-logo: #fff;


  --hero-background: #12151F;
  --hero-title: #fff;
  --hero-subtitle: #05F4B7;
  --hero-desc: #BDBDBD;


  --social-links: #BDBDBD;
  --social-links-hover: #05F4B7;

  --loader-color: #05F4B7;



  --skills-title: #ffffff;
  --skills-subtitle: #12151F;
  --skills-desc: #BDBDBD;
  --skills-background: #12151F;
  --skills-subtitle-background: #05F4B7;




  --footer-background: #12151F;
  --footer-title: #ffff;
  --footer-subtitle: #BDBDBD;
  --footer-copyrights: #BDBDBD;


  --projects-background: #12151F;
  --card-background: #1b1f2b;
  --card-border: #1b1f2b;

  --card-title: #ffff;
  --card-subtitle: #BDBDBD;
  --card-desc: #BDBDBD;


  --featured-card-title: #ffff;
  --featured-card-hover-title: #12151F;
  --featured-card-background: #1b1f2b;
  --featured-card-subtitle: #BDBDBD;
  --featured-card-hover-subtitle: #12151F;
  --featured-card-button: #05F4B7;
  --featured-card-hover-background: #05F4B7;
  --featured-projects-title: #BDBDBD;


  --project-details-heading: #05F4B7;
  --project-details-title: #12151F;
  --project-details-title-background: #05F4B7;
  --project-details-subtitle: #fff;
  --project-details-desc: #BDBDBD;

  --project-details-btn-background: #fff;
  --project-details-btn-text: #12151F;
  --project-details-btn-hover: #05F4B7;

  --about-me-title: #ffff;
  --about-me-sbutitle: #05F4B7;
  --about-me-desc: #BDBDBD;
  --more-info-title: #ffff;

  --list-color: #BDBDBD;


  --painting-background: #1b1f2b;
  --painting-text: #05F4B7;
}




.app {

  background: var(--hero-background);

}





h1,
h2,
h3 {
  margin-top: 0;
  margin-bottom: 0;
}



img {
  width: 100%;
  object-fit: cover;
}





