.projects {
    background: var(--projects-background);

    padding: 8em 0;
}

.projects-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 1.5em;

}