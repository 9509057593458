.skills {
    background: var(--skills-background);
    padding: 3em 0;
}

.skills-title {
    color: var(--skills-title);
    font-weight: 600;
    margin: 0;
}

.skills-subtitle {
    color: var(--skills-subtitle);
    font-weight: 400;
    margin-bottom: 00.5em;
    background: var(--skills-subtitle-background);
    padding: 0em 0.2em;
    border-radius: 3px;
}

.skills-desc {
    color: var(--skills-desc);
    font-weight: 300;
    margin: 0;
    margin-bottom: 0.2em;

}

.skills-content {
    margin-top: 1em;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 1.5em;

}

.skill-content {
    margin-top: 1.5em;
    /* border: 1px solid red; */
}