footer {
    background: var(--footer-background);
    padding: 2.5em 0;
    padding: 5em 0;
}

.footer-container {
    display: flex;
    flex-direction: column;
}

.footer-title {
    color: var(--footer-title);
    margin: 0;
    font-size: 1.3rem;
    margin-top: 1em;
    font-weight: 600;
}

.footer-subtitle {
    color: var(--footer-subtitle);
    margin: 0;
    margin-top: 0.8em;
    font-weight: 400;
}

.footer-link{
    color: var(--footer-subtitle);
    margin: 0;
    margin-top: em;
    display: inline-block;
    margin-top: 0.5em;
    font-weight: 300;
}
.footer-copyrights {
    color: var(--footer-copyrights);
    font-size: 0.75rem;
    font-weight: 300;
    margin-top: 2em;
    opacity: 0.7;
    margin-bottom: 0;
    text-align: center;
}

@media(min-width:767px){
    .footer-container {
        flex-direction: row;
        justify-content: space-between;
     
    }
}
